import HomePage from './pages/home/HomePage'
import './App.css'

function App() {
  return (
    <HomePage />
  );
}

export default App;
